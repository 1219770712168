<template>
    <painel titulo="SENAC - Usuários - INSERIR" icone="pi pi-users" :mostrarRefresh="false">
        <tabela :data="usuariosDisponiveis" dataKey="usuarioId" :globalFilterFields="['nome', 'login']" stateKey="dt-senac-usuarios-selecao">
            <template #botoes>
                <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary" @click="cancelar()" autofocus />
            </template>
            <template #conteudo>
                <Column headerStyle="width: 3em">
                    <template #body="slotProps">
                        <font-awesome-icon
                            icon="check-circle"
                            @click="confirmarInserir(slotProps.data)"
                            size="lg"
                            class="icon-button p-text-primary"
                            title="Selecionar"
                        />
                    </template>
                </Column>
                <Column field="nome" header="Nome" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.nome }}
                    </template>
                </Column>
                <Column field="login" header="Login" :sortable="true">
                    <template #body="slotProps">
                        {{ slotProps.data.login }}
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
</template>

<script>
import UsuariosServices from './services';

export default {
    data() {
        return {
            erros: [],
            usuariosDisponiveis: null,
        };
    },

    methods: {
        cancelar() {
            this.$router.push({ name: 'Senac_Usuarios' });
        },

        confirmarInserir(usuario) {
            this.$confirm.require({
                message: `Tem certeza que deseja inserir o usuário ${usuario.nome}?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.inserir(usuario);
                },
            });
        },

        inserir(usuario) {
            this.$store.dispatch('addRequest');
            let usuarioInseridoDto = {
                usuarioId: usuario.usuarioId,
                nome: usuario.nome,
                login: usuario.login,
            };
            UsuariosServices.inserir(usuarioInseridoDto).then((response) => {
                if (response?.success) {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Usuário',
                        detail: 'Usuário inserido com sucesso',
                        life: 3000,
                    });
                    this.$store.dispatch('setAtualizar', true);
                    this.toDetalhar(usuario);
                } else {
                    this.erros = response.errors;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        obterUsuariosDisponiveis() {
            this.$store.dispatch('addRequest');
            UsuariosServices.obterUsuariosDisponiveis().then((response) => {
                if (response?.success) {
                    this.usuariosDisponiveis = response.data;
                } else {
                    this.usuarios = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        toDetalhar(usuario) {
            this.$router.push({ name: 'Senac_Usuarios_Detalhar', params: { id: usuario.usuarioId } });
        },
    },

    mounted() {
        this.obterUsuariosDisponiveis();
    },
};
</script>
